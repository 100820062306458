import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const isShowingToast = ref(false)
const toastMessage = ref('')
const titleToast = ref('')
const isSuccessToast = ref(false)

export function useToast() {
  const { t } = useI18n()

  const defaultMessage = t('errors.general')
  const defaultTitle = t('general.admin')

  const showToast = ({ title, message, duration = 4000, isSuccess = false } = {}) => {
    titleToast.value = title || defaultTitle
    toastMessage.value = message || defaultMessage
    isSuccessToast.value = isSuccess

    isShowingToast.value = true

    setTimeout(() => {
      closeToast()
    }, duration)
  }

  const closeToast = () => {
    isShowingToast.value = false
    toastMessage.value = defaultMessage
  }

  return { isShowingToast, toastMessage, titleToast, showToast, closeToast, isSuccessToast }
}
