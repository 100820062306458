import { http } from '@/infrastructure/http'
import UserTransformer from '@/infrastructure/transformers/UserTransformer'

import BaseAPI from './baseAPI'
class UserAPI extends BaseAPI {
  constructor(service) {
    super({
      service: service,
      baseURL: '/users/',
      defaultTransformer: UserTransformer,
    })
  }

  async me() {
    const { data } = await this.service.get(`${this.baseURL}me/`)
    return UserTransformer.fetch(data)
  }
}

export default new UserAPI(http)
