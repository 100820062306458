import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'

class UserTransformer extends BaseTransformer {
  static fetch(user) {
    return {
      id: user.id,
      username: user.username,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      phone: user.profile ? user.profile.phone : null,
      isStaff: user.is_staff,
      fullName: `${user.first_name} ${user.last_name}`,
      hasOpenOrders: user.has_open_orders,
      organizations: user.organizations.join(', '),
    }
  }
}

export default UserTransformer
