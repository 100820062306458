import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'

class UserTableTransformer extends BaseTransformer {
  static fetch(user) {
    return {
      id: user.id,
      username: user.username || '-',
      firstName: user.first_name || '-',
      lastName: user.last_name || '-',
      hasOpenOrders: user.has_open_orders,
      organizations: user.organizations.join(', ') || '-',
      phone: user.profile ? user.profile.phone || '-' : '-',
    }
  }
}

export default UserTableTransformer
