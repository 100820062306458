import { http } from '@/infrastructure/http'
import StoreHoursTransformer from '@/infrastructure/transformers/StoreHoursTransformer'
import StoreTransformer from '@/infrastructure/transformers/StoreTransformer'

import BaseAPI from './baseAPI'

class StoresAPI extends BaseAPI {
  constructor(service) {
    super({
      service: service,
      baseURL: '/stores/',
      defaultTransformer: StoreTransformer,
    })
  }

  async listStoreHours(id) {
    return await super.listWithoutPagination({
      url: `${this.baseURL}${id}/store-hours/`,
      transformer: StoreHoursTransformer,
    })
  }

  updateStoreHours(id, payload) {
    return this.service.patch(
      `${this.baseURL}${id}/store-hours/`,
      StoreHoursTransformer.sendCollection(payload),
    )
  }
}

export default new StoresAPI(http)
