import { dateTimeFormat } from '@/ui/plugins/vue-i18n'

export const formatHoursWithMinutes = minutes => {
  const date = new Date()
  date.setHours(0, minutes, 0)

  return new Intl.DateTimeFormat('es', dateTimeFormat.shortTime).format(date)
}

export const formatWithFullDate = date => {
  return new Intl.DateTimeFormat('es', dateTimeFormat.shortDateTime).format(new Date(date))
}

export const getDayRange = day => {
  const todayStart = new Date(day)
  todayStart.setUTCHours(0, 0, 0, 0)

  const todayEnd = new Date(day)
  todayEnd.setUTCHours(23, 59, 59, 999)

  return { todayStart, todayEnd }
}

export const getYearRange = year => {
  const dateStartYear = new Date(year, 0, 1)
  dateStartYear.setUTCHours(0, 0, 0, 0)

  const dateEndYear = new Date(year, 11, 31)
  dateEndYear.setUTCHours(23, 59, 59, 999)

  return { dateStartYear, dateEndYear }
}
