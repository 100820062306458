import * as Sentry from '@sentry/vue'

const dsn = import.meta.env.VUE_APP_SENTRY_DSN
const tracesSampleRate = import.meta.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE
const apiUrl = import.meta.env.VUE_APP_SENTRY_API_URL
const environment = import.meta.env.VUE_APP_SENTRY_ENVIRONMENT

export const initSentry = (app, router) => {
  Sentry.init({
    app,
    dsn,
    environment: environment || 'production',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate,
    tracePropagationTargets: ['localhost', apiUrl],
  })
}
