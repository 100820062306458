import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'

export default class StoreHoursTransformer extends BaseTransformer {
  static fetch(data) {
    return {
      weekday: data.weekday,
      openingTime: data.opening_time,
      closingTime: data.closing_time,
    }
  }

  static send(data) {
    return {
      weekday: data.weekday,
      opening_time: data.openingTime,
      closing_time: data.closingTime,
    }
  }
}
