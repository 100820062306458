import { http } from '@/infrastructure/http'
import OptionsTransformer from '@/infrastructure/transformers/OptionsTransformer'

import BaseAPI from './baseAPI'

class OptionsAPI extends BaseAPI {
  constructor(service) {
    super({
      service: service,
      baseURL: '/options/',
      defaultTransformer: OptionsTransformer,
    })
  }
}

export default new OptionsAPI(http)
