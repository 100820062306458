import { computed, ref } from 'vue'

import { listOptions } from '@/application/options'

const options = ref(new Map())

const isOptionEqual = (name, value) => {
  return computed(() => options.value.get(name) === value)
}

export function useOptions() {
  const setOptions = async () => {
    const data = await listOptions()

    data.forEach(({ name, value }) => {
      options.value.set(name, value)
    })
  }

  const updateOfflineMode = offline => {
    options.value.set('offline_mode', offline ? '1' : '0')
  }

  const isOffline = isOptionEqual('offline_mode', '1')
  const allowFalseCheckouts = isOptionEqual('allow_false_checkouts', '1')
  const allowPartialItems = isOptionEqual('allow_partial_items', '1')
  const controlByUsers = isOptionEqual('criteria_for_random_controls', 'users')
  const checkStockInStore = isOptionEqual('check_stock_in_store', '1')
  const allowOpenOrders = isOptionEqual('allow_open_orders', '1')

  return {
    setOptions,
    updateOfflineMode,
    options,
    isOffline,
    allowFalseCheckouts,
    allowPartialItems,
    controlByUsers,
    checkStockInStore,
    allowOpenOrders,
  }
}
