import axios from 'axios'
import qs from 'qs'

import { authInterceptor, responseErrorInterceptor, responseInterceptor } from './interceptors'

const baseUrl = import.meta.env.VUE_APP_API_URL
const apiVersion = 'api/v1'

const httpClient = axios.create({
  baseURL: `${baseUrl}${apiVersion}`,
  'Content-type': 'application/json',
})

httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

httpClient.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
}

export const http = httpClient
