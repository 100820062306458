import { getMe } from '@/application/users'
import authAPI from '@/infrastructure/api/authAPI'
import authService from '@/infrastructure/services/authService'
import { useCarts } from '@/ui/composables/useCart'
import { useStore } from '@/ui/composables/useStore'

const { clearStores } = useStore()
const { setCarts } = useCarts()

const logout = () => {
  authService.clearToken()
  clearStores()
  setCarts([])
}

const login = async ({ payload }) => {
  const {
    data: { token: token },
  } = await authAPI.login(payload)
  authService.setToken(token)
  await getMe()
}

export { logout, login }
