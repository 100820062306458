import { http } from '@/infrastructure/http'
import LoginTransformer from '@/infrastructure/transformers/LoginTransformer'

class AuthAPI {
  constructor() {
    this.baseURL = '/auth/jwt'
  }

  async login(payload) {
    const data = LoginTransformer.send(payload)
    return await http.post(`${this.baseURL}/token/`, data, {
      headers: { Authorization: '' },
    })
  }

  async refresh(token) {
    return http.post(`${this.baseURL}/refresh/`, { token })
  }
}

export default new AuthAPI()
