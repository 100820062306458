export default [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/ui/pages/home/HomePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/statistics',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'StatisticsPage',
        component: () => import('@/ui/pages/statistics/StatisticsPage.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'UsersPage',
    component: () => import('@/ui/pages/users/UsersPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cart-detail/:id',
    name: 'CartDetail',
    component: () => import('@/ui/pages/cart-detail/CartDetailPage.vue'),
    meta: {
      requiresAuth: true,
      breadcrumb: 'breadcrumbs.cartDetail',
    },
  },
  {
    path: '/carts-history',
    name: 'CartsHistoryPage',
    component: () => import('@/ui/pages/carts-history/CartsHistoryPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/ui/pages/login/LoginPage.vue'),
    meta: {
      isPublicOnly: true,
    },
  },
  {
    path: '/customer-detail',
    redirect: '/',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':id',
        name: 'CustomerDetailPage',
        component: () => import('@/ui/pages/customer-detail/CustomerDetailPage.vue'),
        meta: {
          breadcrumb: 'breadcrumbs.customerDetail',
        },
      },
      {
        path: ':id/edit',
        name: 'CustomerDetailEditPage',
        component: () => import('@/ui/pages/customer-detail/CustomerDetailPage.vue'),
        meta: {
          breadcrumb: 'breadcrumbs.customerDetail',
        },
      },
      {
        path: 'user-cart-summary/:id',
        name: 'UserCartSummary',
        component: () => import('@/ui/pages/user-carts-summary/UserCartSummaryPage.vue'),
        meta: {
          breadcrumb: ['breadcrumbs.customerDetail', 'breadcrumbs.control'],
        },
      },
    ],
  },
  {
    path: '/:lang(en|sv)/:path(.*)',
    component: () => import('@/ui/pages/ChangeLanguage.vue'),
  },
  {
    path: '/:path(.*)/:lang(en|sv)',
    component: () => import('@/ui/pages/ChangeLanguage.vue'),
  },
  {
    path: '/:lang(en|sv)',
    component: () => import('@/ui/pages/ChangeLanguage.vue'),
  },
  {
    path: '/:path(.*)',
    component: () => import('@/ui/pages/NotFound.vue'),
  },
]
