import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'
import { formatHoursWithMinutes } from '@/shared/utils/date'

class StoreTransformer extends BaseTransformer {
  static fetch(store) {
    if (store) {
      return {
        id: store.id,
        name: store.name,
        activePeriod: store.active_period,
        gln: store.gln,
        cartsForRandomControl: store.carts_for_random_control,
        usersForRandomControl: store.users_for_random_control,
      }
    }
    return null
  }

  static send(store) {
    if (store.activePeriod) {
      return {
        active_period: formatHoursWithMinutes(store.activePeriod),
      }
    }
    if (store.cartsForRandomControl) {
      return {
        carts_for_random_control: store.cartsForRandomControl,
      }
    }
    if (store.usersForRandomControl) {
      return {
        users_for_random_control: store.usersForRandomControl,
      }
    }
    return store.id.toString()
  }
}

export default StoreTransformer
