import languageService from '@/infrastructure/services/languageService'
import { i18n } from '@/ui/plugins/vue-i18n'

let hasToInitLanguage = true

export const languageGuard = () => {
  if (hasToInitLanguage) {
    i18n.global.locale.value = languageService.getLanguage()
    hasToInitLanguage = false
  }
}
