const KEY_STORE = 'controltower-selected-store'
const KEY_USER = 'controltower-user'

export const setLocalStorageUser = user => {
  localStorage.setItem(KEY_USER, user.id)
}

export const getUser = () => {
  return JSON.parse(localStorage.getItem(KEY_USER))
}

export const saveLocalStorageSelectedStore = store => {
  const user = getUser()
  const key = `${KEY_STORE}-${user}`
  localStorage.setItem(key, JSON.stringify(store))
}

export const getLocalStorageSelectedStore = () => {
  const user = getUser()
  const key = `${KEY_STORE}-${user}`
  return JSON.parse(localStorage.getItem(key))
}

export const removeLocalStorageSelectedStore = () => {
  const user = getUser()
  const key = `${KEY_STORE}-${user}`
  localStorage.removeItem(key)
}
