import { logout } from '@/application/auth'
import { getMe } from '@/application/users'
import authService from '@/infrastructure/services/authService'
import { NotAuthenticatedError } from '@/shared/constants/errors'
import { useUser } from '@/ui/composables/useUser'

const authGuard = async to => {
  if (isNotAuthenticated()) {
    if (needsToAuthenticate(to)) {
      return {
        name: 'LoginPage',
        replace: true,
      }
    }
    return
  }

  try {
    await authService.inspectToken()
    await inspectUser()
  } catch (error) {
    console.error(error)
    logout()
    return { name: 'LoginPage' }
  }

  if (isPublicOnly(to)) {
    return { name: 'HomePage' }
  }
}

export default authGuard

const isNotAuthenticated = () => !authService.isAuthenticated()

const needsToAuthenticate = to => to.matched.some(m => m.meta.requiresAuth)

const isPublicOnly = to => to.matched.some(m => m.meta.isPublicOnly)

const { user, setUser } = useUser()

const inspectUser = async () => {
  if (user.value === null) {
    try {
      const user = await getMe()
      setUser(user)
    } catch (error) {
      throw new NotAuthenticatedError('could not refresh the user')
    }
  }
}
