import userAPI from '@/infrastructure/api/userAPI'
import UserTableTransformer from '@/infrastructure/transformers/UserTableTransformer'
import { NotStaffUserError } from '@/shared/constants/errors'
import { setLocalStorageUser } from '@/shared/utils/localStorage'
import { useOptions } from '@/ui/composables/useOptions'
import { useStore } from '@/ui/composables/useStore'
import { useUser } from '@/ui/composables/useUser'

const { setUser } = useUser()
const { listStores } = useStore()
const { setOptions } = useOptions()

const getUserInformation = ({ params }) => {
  return userAPI.list({ config: { params }, transformer: UserTableTransformer })
}

const getMe = async () => {
  const localUser = await userAPI.me()
  if (localUser.isStaff) {
    setUser(localUser)
    setLocalStorageUser(localUser)
    await setOptions()
    await listStores()
    return localUser
  }
  throw new NotStaffUserError()
}

export { getMe, getUserInformation }
