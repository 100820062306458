import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'

class LoginTransformer extends BaseTransformer {
  static send(loginData) {
    return {
      username: loginData.username,
      password: loginData.password,
    }
  }
}

export default LoginTransformer
