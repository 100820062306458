import storesAPI from '@/infrastructure/api/storesAPI'
import {
  getLocalStorageSelectedStore,
  saveLocalStorageSelectedStore,
} from '@/shared/utils/localStorage'
import { removeLocalStorageSelectedStore } from '@/shared/utils/localStorage'

import { useState } from './useState'

const [selectedStore, setSelectedStore] = useState(null)
const [stores, setStores] = useState([])

export function useStore() {
  const updateStore = async ({ id, payload }) => {
    const store = await storesAPI.update(id, payload)
    updateStoreFromList(store)
    saveSelectedStore(store)
    return store
  }

  const updateStoreFromList = updatedStore => {
    const storeIndex = stores.value.findIndex(store => store.gln === updatedStore.gln)

    if (storeIndex !== -1) {
      const updatedStores = [...stores.value]
      updatedStores[storeIndex] = updatedStore
      setStores(updatedStores)
    }
  }

  const listStores = async () => {
    const currentStores = await storesAPI.fullList()
    setStores(currentStores)

    if (selectedStore.value === null) {
      const currentSelectedStore = getLocalStorageSelectedStore()

      if (currentSelectedStore !== null) {
        saveSelectedStore(currentSelectedStore)
      }
    }
  }

  const saveSelectedStore = store => {
    saveLocalStorageSelectedStore(store)
    setSelectedStore(store)
  }

  const clearStores = () => {
    setStores([])
    setSelectedStore(null)
    removeLocalStorageSelectedStore()
  }

  return {
    selectedStore,
    saveSelectedStore,
    updateStore,
    listStores,
    stores,
    setStores,
    clearStores,
  }
}
