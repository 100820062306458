import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

export const dateTimeFormat = {
  shortTime: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  shortDateTime: {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  datepicker: {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  chart: {
    month: 'short',
    year: 'numeric',
  },
}

const datetimeFormats = {
  sv: dateTimeFormat,
  en: dateTimeFormat,
}

export const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
  datetimeFormats,
})
