import BaseTransformer from '@/infrastructure/transformers/BaseTransformer'

export default class OptionsTransformer extends BaseTransformer {
  static fetch(option) {
    return {
      name: option.name,
      value: option.value,
    }
  }
}
