import { ref } from 'vue'

const user = ref(null)

export function useUser() {
  const setUser = userInfo => {
    user.value = userInfo
  }

  return { user, setUser }
}
