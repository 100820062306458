<script setup>
import IconCheck from '@/ui/components/icons/IconCheck.vue'
import IconClose from '@/ui/components/icons/IconClose.vue'
import IconFailure from '@/ui/components/icons/IconFailure.vue'
import { useToast } from '@/ui/composables/useToast'

const { isShowingToast, closeToast, toastMessage, titleToast, isSuccessToast } = useToast()
</script>

<template>
  <transition
    enter-active-class="duration-300 ease-out"
    enter-from-class="transform opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0"
  >
    <div v-if="isShowingToast" class="fixed z-20 left-[48px] top-[62px] min-w-[auto]">
      <div
        class="w-[487px] pl-4 pr-2 py-2.5 pt bg-white rounded border flex items-center shadow-lg"
      >
        <section class="min-w-[35px]">
          <IconCheck v-if="isSuccessToast" width="35" height="35" />
          <IconFailure v-if="!isSuccessToast" width="35" height="35" />
        </section>

        <section>
          <h3 class="font-bold">{{ titleToast }}</h3>
          <span class="text-sm text-gray-500">{{ toastMessage }}</span>
        </section>

        <IconClose
          class="ml-auto self-start cursor-pointer min-w-[35px]"
          width="16"
          height="16"
          @click="closeToast"
        />
      </div>
    </div>
  </transition>
</template>
