export class NotAuthenticatedError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotAuthenticatedError'
    this.messageI18n = 'errors.authRequired'
  }
}

export class NotValidCredentialsError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotValidCredentialsError'
    this.messageI18n = 'errors.notValidCredentials'
  }
}

export class NotStaffUserError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotStaffUserError'
    this.messageI18n = 'errors.notStaffUser'
  }
}

export class NoStoresError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NoStoresError'
    this.messageI18n = 'errors.noStores'
  }
}

export class BackendError extends Error {
  constructor(message) {
    super(message)
    this.name = 'BackendError'
  }
}
