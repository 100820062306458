import { jwtDecode } from 'jwt-decode'

import authAPI from '@/infrastructure/api/authAPI'
import { NotAuthenticatedError } from '@/shared/constants/errors'

const KEY = import.meta.env.VUE_APP_TOKEN_NAME

function factory() {
  return {
    getToken() {
      return localStorage.getItem(KEY)
    },
    setToken(value) {
      return localStorage.setItem(KEY, value)
    },
    clearToken() {
      localStorage.removeItem(KEY)
    },
    isAuthenticated() {
      return !!this.getToken()
    },
    getHeader() {
      const token = this.getToken()
      if (token) {
        return 'Bearer ' + token
      }
      return ''
    },
    async inspectToken() {
      const token = this.getToken()
      if (token) {
        const decoded = jwtDecode(token)
        const expiration = decoded.exp

        const refreshExpiration = decoded.refresh_exp
        if (hasExpired(expiration)) {
          throw new NotAuthenticatedError('token has expired')
        } else if (expiresInSeconds(expiration, 1800)) {
          if (inRefreshPeriod(refreshExpiration)) {
            try {
              const {
                data: { token: newToken },
              } = await authAPI.refresh(token)
              this.setToken(newToken)
            } catch (error) {
              throw new NotAuthenticatedError('could not refresh the token')
            }
          }
        }
      } else {
        throw new NotAuthenticatedError('No token!')
      }
    },
  }
}

function now() {
  return Date.now() / 1000
}

function hasExpired(expiration) {
  return expiration < now()
}

function expiresInSeconds(expiration, seconds) {
  return expiration - now() < seconds
}

function inRefreshPeriod(refreshExpiration) {
  return refreshExpiration > now()
}

export default factory()
