import authService from '@/infrastructure/services/authService'
import { NotAuthenticatedError, NotValidCredentialsError } from '@/shared/constants/errors'

const authInterceptor = config => {
  const header = authService.getHeader()
  if (header) {
    config.headers.Authorization = header
  }
  return config
}

const responseInterceptor = response => {
  return response
}

const NO_ACTIVE_ACCOUNT_CODE = 'no_active_account'

const responseErrorInterceptor = error => {
  const code = parseInt(error.response?.status)
  if (code === 401) {
    const customCode = error.response?.data?.code
    if (customCode === NO_ACTIVE_ACCOUNT_CODE) {
      throw new NotValidCredentialsError(error)
    }
    throw new NotAuthenticatedError(error)
  }
  return Promise.reject(error)
}

export { authInterceptor, responseInterceptor, responseErrorInterceptor }
